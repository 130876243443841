@mixin button-theme($name, $value: var(--#{$prefix}#{$name})) {
  $color: $value;

  .#{'button--' + $name} {
    @if $name == 'light' {
      $color: black;
    } @else {
      $color: white;
    }

    --#{$prefix}btn-color: #{$color};
    --#{$prefix}btn-bg: #{$value};
    --#{$prefix}btn-border-color: #{$value};
    --#{$prefix}btn-hover-color: #{$color};
    --#{$prefix}btn-hover-bg: color-mix(in srgb, #{$value} #{100% - $btn-hover-shade-amount}, black);
    --#{$prefix}btn-hover-border-color: color-mix(in srgb, #{$value} #{100% - $btn-hover-shade-amount}, black);
    --#{$prefix}btn-focus-shadow-rgb: color-mix(in srgb, #{$value} #{100% - $btn-hover-shade-amount}, black);
    --#{$prefix}btn-active-color: #{$color};
    --#{$prefix}btn-active-bg: color-mix(in srgb, #{$value} #{100% - $btn-active-shade-amount}, black);
    --#{$prefix}btn-active-border-color: color-mix(in srgb, #{$value} #{100% - $btn-active-shade-amount}, black);
    //--#{$prefix}btn-active-shadow: #{$btn-active-box-shadow};
    --#{$prefix}btn-disabled-color: #{$color};
    --#{$prefix}btn-disabled-bg: #{$value};
    --#{$prefix}btn-disabled-border-color: #{$value};
  }

  .button-outline#{'.button--' + $name} {
    --#{$prefix}btn-color: #{$value};
    --#{$prefix}btn-border-color: #{$value};
    --#{$prefix}btn-hover-color: #{$color};
    --#{$prefix}btn-hover-bg: color-mix(in srgb, #{$value} 20%, transparent);
    --#{$prefix}btn-hover-border-color: #{$value};
    --#{$prefix}btn-active-color: #{$color};
    --#{$prefix}btn-active-bg: #{$value};
    --#{$prefix}btn-active-border-color: #{$value};
    --#{$prefix}btn-disabled-color: #{$value};
    --#{$prefix}btn-disabled-bg: transparent;
    --#{$prefix}btn-disabled-border-color: #{$value};
    --#{$prefix}gradient: none;
  }
}

@each $name, $color in $theme-colors {
  @include button-theme($name);
}
@each $name, $color in $button-themes {
  @include button-theme($name, $color);
}
