// Bootstrap
$font-size-base: 1rem;

$font-family-sans-serif: 'alegreya-sans', sans-serif;
$font-family-serif: 'alegreya-sans', serif;

$font-sizes: (
  1: 48px,
  2: 40px,
  3: 32px,
  4: 25px,
  5: 20px,
  6: 16px,
  body-xxs: 12px,
  body-xs: 14px,
  body-sm: 16px,
  body-md: 18px,
  body-lg: 24px,
);
// golden ratio uitgaande vanuit grootste font formaat)
$display-font-sizes: (
  1: 80px,
  2: 72px,
  3: 64px,
  4: 56px,
  5: 48px,
  6: 42px,
);

$text-max-widths: (
  xxs: 480px,
  xs: 550px,
  sm: 720px,
  md: 900px,
  lg: 1000px,
  xl: 1140px,
  xxl: 1320px,
);

$headings-font-family: $font-family-serif;
$headings-font-weight: 700;
$headings-line-height: 1.1;

$display-font-family: $font-family-sans-serif;
$display-font-style: null;
$display-font-weight: 600;
$display-line-height: $headings-line-height;

$font-family-base: $font-family-sans-serif;
$font-weight-base: 400;

$lead-font-size: map-get($font-sizes, body-md);
$lead-font-weight: 600;

// Other
//? Put own extra variables here

$paragraph-line-height: 1.5;
