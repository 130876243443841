@for $i from 1 through $grid-columns {
  .inset-s-#{$i} {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2)); // KLOPT!
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12); // KLOPT!
    margin-left: calc(((var(--one-col) * #{$i}) + (var(--bs-gutter-x) * #{$i})) * -1);

    @media screen and (max-width: 960px) {
      --real-container-width: calc(100vw - (var(--bs-gutter-x) * 2)); // KLOPT!
      --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12); // KLOPT!
    }
  }

  // duplicate (maybe refactor?)
  .inset-e-#{$i} {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2)); // KLOPT!
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12); // KLOPT!
    margin-right: calc(((var(--one-col) * #{$i}) + (var(--bs-gutter-x) * #{$i})) * -1);

    @media screen and (max-width: 960px) {
      --real-container-width: calc(100vw - (var(--bs-gutter-x) * 2)); // KLOPT!
      --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12); // KLOPT!
    }
  }
}

@each $breakpoint, $value in $grid-breakpoints {
  @for $i from 1 through $grid-columns {
    @include media-breakpoint-up($breakpoint) {
      .inset-s-#{$breakpoint}-#{$i} {
        --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2)); // KLOPT!
        --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12); // KLOPT!
        margin-left: calc(((var(--one-col) * #{$i}) + (var(--bs-gutter-x) * #{$i})) * -1);
      }

      // duplicate (maybe refactor?)
      .inset-e-#{$breakpoint}-#{$i} {
        --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2)); // KLOPT!
        --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12); // KLOPT!
        margin-right: calc(((var(--one-col) * #{$i}) + (var(--bs-gutter-x) * #{$i})) * -1);
      }
    }
  }
}

@mixin pull() {
  $containers-not-fullwidth: map-remove($container-max-widths, 'sm', 'md');
  --space-left: calc(var(--bs-gutter-x) * -1);

  @each $bp, $size in $containers-not-fullwidth {
    @include media-breakpoint-up($bp) {
      --space-left: calc(((100vw - var(--container-max-width) + (var(--bs-gutter-x) * 2)) / 2) * -1);
    }
  }
}

.pull-s {
  @include pull();
  margin-left: var(--space-left);
}
.pull-e {
  @include pull();
  margin-right: var(--space-left);
}

.row {
  @include media-breakpoint-down(md) {
    --bs-gutter-x: 0.8rem;
  }
}
