.chip {
  --chip-background-color: var(--bs-secondary);
  --chip-color: white;
  --chip-size: #{rem(24px)};

  @include size(var(--chip-size));
  background: var(--chip-background-color);
  color: var(--chip-color);
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  padding-bottom: 0.3ex;
  text-align: center;

  @each $name, $color in $theme-colors {
    &.chip--#{$name} {
      --chip-background-color: #{$color};
      --chip-background-color-alpha: #{rgba($color, 0.09)};
    }
  }
}

.chip--label {
  --chip-background-color: black;
  --chip-background-color-alpha: #{rgba(black, 0.09)};

  display: inline-flex;
  align-items: center;
  border-radius: 2em;
  padding: 0 calc(var(--chip-size) / 2);
  height: var(--chip-size);
  width: auto;
  background-color: var(--chip-background-color-alpha);
  color: var(--chip-background-color);
  border: 1px solid transparent;

  @include media-breakpoint-down(sm) {
    padding: 0.3em 0.8em;
  }

  &.chip--outline {
    border-color: var(--chip-background-color);
    background-color: transparent;
  }

  @each $name, $color in $theme-colors {
    &.chip--#{$name} {
      --chip-background-color: #{$color};
      --chip-background-color-alpha: #{rgba($color, 0.09)};
    }
  }
}
