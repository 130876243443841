.mat-mdc-checkbox {
  --mdc-checkbox-unselected-icon-color: var(--bs-light-border-subtle);
  --mdc-checkbox-unselected-hover-icon-color: var(--bs-tertiary-color);
  --mdc-checkbox-state-layer-size: 32px;

  .mdc-form-field {
    align-items: start;
    color: var(--bs-body-color);
  }

  .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate='true']) ~ .mdc-checkbox__background {
    background: var(--bs-tertiary-bg);
  }

  .mdc-checkbox__background {
    border-radius: 5px;
  }

  .mdc-checkbox__checkmark {
    padding: 2px;
  }

  label {
    margin-top: 0.35em;
  }
}
