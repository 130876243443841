@each $name, $color in $grays {
  .#{'bg-gray-' + $name} {
    background-color: var(--bs-gray-#{$name}) !important;
  }
}

@for $i from 1 through 9 {
  .bg-primary-#{$i}00 {
    background-color: var(--bs-primary-#{$i}00) !important;
  }
}
